import { navigate, PageProps } from 'gatsby';
import { useEffect } from 'react';

interface RedirectPageProps {
  toPath: string;
}
const RedirectPage = ({
  pageContext,
}: PageProps<null, RedirectPageProps>): null => {
  const { toPath } = pageContext;

  useEffect(() => {
    const redirect = async () => {
      await navigate(toPath || '', { replace: true });
    };
    redirect().catch(() => {});
  });

  return null;
};

export default RedirectPage;
